import { Button, makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { ApplicationStatus, ApplicationWithAgentDTO } from 'dtos/application';
import { DeepScanDTO } from 'dtos/deep-scan';
import { ReactComponent as AppleLogo } from 'images/apple.svg';
import { ReactComponent as WindowsLogo } from 'images/windows.svg';
import React, { useCallback, useState } from 'react';
import { UNINSTALL_AGENTS_MAC_ONE_LINER, UNINSTALL_AGENTS_ONE_LINER } from 'telivy-constants';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { SecurityCard } from 'templates/SecurityAssessments/components/SecurityCard';

import { CONFIGURATION_STEP } from './index';
import { SectionTemplate } from './SectionTemplate';

const useStyles = makeStyles((theme) => ({
  boxesContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  box: {
    flex: 1,
    height: '100%',
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
  },
  boxTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    fontSize: 16,
  },
  boxDescription: {
    fontSize: 14,
    margin: 0,
  },
  boxSubTitle: {
    fontWeight: 'bold',
    fontSize: 12,
    marginTop: theme.spacing(2),
  },
  boxButton: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  boxButtonMac: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  title: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  or: {
    fontWeight: 'bold',
  },

  badge: {
    marginTop: theme.spacing(2),
    textAlign: 'left',
  },
  badge2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'left',
    justifyContent: 'flex-start',
    '& div': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flex: 1,
    },
  },
  select: {
    marginBottom: theme.spacing(2),
  },

  osContainer: {
    display: 'flex',
    gap: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  osItem: {
    ...TYPOGRAPHY.EXTRA_SMALL_BOLD,
    cursor: 'pointer',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
    color: COLORS.GREY_1,
    backgroundColor: COLORS.GREY_6,
    borderRadius: theme.spacing(1.5),
    display: 'flex',
    gap: theme.spacing(0.5),
    flexDirection: 'column',
    alignItems: 'center',
    textDecoration: 'none',
    transition: 'all .2s linear',
    minWidth: theme.spacing(12.5),
    boxSizing: 'border-box',

    '&:not($osActive):hover': {
      backgroundColor: COLORS.GREY_4,
    },
  },
  icon: {
    '& path': {
      fill: '#BFC1D2',
    },
  },
  osActive: {
    backgroundColor: COLORS.BLUE_1,
    color: COLORS.WHITE,
    '& $icon path': {
      fill: COLORS.WHITE,
    },
  },

  downloadBox: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'start',

    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: 'inherit',
    },
  },
  copyButton: {},
  copyInstallButton: {
    marginBottom: theme.spacing(3),
  },
  oneLiner: {
    padding: theme.spacing(1),
    backgroundColor: COLORS.GREY_4,
    fontFamily: 'monospace',
  },
  fullDiskImage: {
    width: '100%',
  },
  noMargin: {
    marginTop: 0,
    marginBottom: 0,
  },
}));

enum OS {
  WINDOWS = 'Windows',
  MAC = 'Mac',
}

interface Props {
  deepScan: DeepScanDTO;
  application: ApplicationWithAgentDTO;
  onVerify: () => void;
}

export const UninstallAgentsSection = ({ deepScan, application, onVerify }: Props) => {
  const classes = useStyles();
  const isMacDefault =
    deepScan.actifileUninstallKey || application.applicationStatus == ApplicationStatus.CONFIGURATION_PENDING;

  const [selected, setSelected] = useState(isMacDefault ? OS.MAC : OS.WINDOWS);

  const renderContent = useCallback(() => {
    const agentsIds = true; //deepScan.nodewareId;

    switch (selected) {
      case OS.WINDOWS:
        return (
          <>
            {/*<div className={classes.box}>
              <div className={classes.boxTitle}>Supported Versions:</div>
              <p className={classes.boxDescription}>
                Windows 10 64-bit (Enterprise, Pro, Home, Education)
                <br />
                Windows 11 64-bit (Enterprise, Pro, Home, Education)
                <br />
                Windows Server 2016
                <br />
                Windows Server 2019
                <br />
                Windows Server 2022
              </p>
            </div>*/}

            {false && !deepScan?.actifileUninstallKey && (
              <SecurityCard.Badge variant='info' className={classes.badge}>
                We are building custom uninstallers for you, please check back later!
              </SecurityCard.Badge>
            )}

            <div className={classes.boxesContainer}>
              <div className={classes.box}>
                <div className={classes.boxTitle}>Option 1: Agentless Deployment</div>
                <p className={classes.boxDescription}>
                  <SecurityCard.Badge variant='info' className={classes.badge2}>
                    No agents installed, no admin access required. No uninstall needed.
                  </SecurityCard.Badge>
                  Telivy was run without leaving any footprint, so no action needed here.
                </p>
              </div>
            </div>

            <div className={classes.boxesContainer}>
              <div className={classes.box}>
                <div className={classes.boxTitle}>Option 2: Agent Deployment</div>
                <p className={classes.boxDescription}>
                  <SecurityCard.Badge variant='info' className={classes.badge2}>
                    Agents installed, admin access required. Supports one-click rescan and uninstall.
                  </SecurityCard.Badge>
                  Use the Uninstall All button on the assets page for one-click uninstall or follow instructions for
                  RMM.
                </p>

                <div className={classes.boxDescription}>
                  <i>
                    <br />
                    Instructions for RMM:
                  </i>
                  <ul className={classes.noMargin}>
                    <li>
                      Use the Powershell terminal or the Execute Script option in RMM to run the following command as
                      Administrator or System User.
                    </li>
                  </ul>
                </div>
                <p className={classes.oneLiner}>
                  &lt;# Uninstall Telivy for {application.applicationResponse.organization_name} #&gt;
                  <br />
                  {UNINSTALL_AGENTS_ONE_LINER.replace('TELIVY_KEY', deepScan.id)}
                </p>

                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `<# Uninstall Telivy for ${application.applicationResponse.organization_name} #>\n` +
                        UNINSTALL_AGENTS_ONE_LINER.replace('TELIVY_KEY', deepScan.id),
                    );
                  }}
                >
                  Copy Uninstall Command
                </Button>
              </div>
            </div>
          </>
        );
      case OS.MAC:
        return (
          <>
            {/*<div className={classes.box}>
              <div className={classes.boxTitle}>Supported Versions:</div>
              <p className={classes.boxDescription}>
                macOS 10.14 Mojave
                <br />
                macOS 10.15 Catalina
                <br />
                macOS 11 Big Sur
                <br />
                macOS 12 Monterey
                <br />
                macOS 13 Ventura
              </p>
            </div>*/}

            {!agentsIds && (
              <SecurityCard.Badge variant='info' className={classes.badge}>
                We are building custom uninstallers for you, please check back later!
              </SecurityCard.Badge>
            )}

            <div className={classes.boxesContainer}>
              {agentsIds && (
                <div className={classes.box}>
                  <div className={classes.boxTitle}>To Uninstall after the risk assessment is completed:</div>
                  <p className={classes.boxDescription}>
                    Run the following command on terminal
                    <br />
                    OR via RMM as System User
                  </p>
                  <p className={classes.oneLiner}>{UNINSTALL_AGENTS_MAC_ONE_LINER}</p>

                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      navigator.clipboard.writeText(UNINSTALL_AGENTS_MAC_ONE_LINER);
                    }}
                  >
                    Copy Uninstall Command
                  </Button>
                </div>
              )}
            </div>

            {/*<SecurityCard.Badge variant='info' className={classes.badge}>
              We are working on supporting Vulnerability and PII scans on Mac. Please contact us for more information.
            </SecurityCard.Badge>*/}
          </>
        );
      default:
        return null;
    }
  }, [classes, selected, deepScan, application]);

  return (
    <SectionTemplate
      onVerify={onVerify}
      deepScan={deepScan}
      type={CONFIGURATION_STEP.INSTALLATION}
      title='Remove Telivy'
      description={'Choose your OS to see the instructions accordingly:'}
      hideVerify={true}
    >
      {false && !deepScan?.actifileUninstallKey && (
        <SecurityCard.Badge variant='info' className={classes.badge}>
          We are building custom uninstallers for you, please check back later!
        </SecurityCard.Badge>
      )}

      <div className={classes.osContainer}>
        {[
          { os: OS.WINDOWS, icon: <WindowsLogo className={classes.icon} /> },
          { os: OS.MAC, icon: <AppleLogo className={classes.icon} /> },
        ].map((l, idx) => (
          <div
            tabIndex={0}
            role='button'
            key={idx}
            className={cx(classes.osItem, l.os === selected && classes.osActive)}
            onClick={() => setSelected(l.os)}
          >
            {l.icon}
            {l.os}
          </div>
        ))}
      </div>

      {renderContent()}
    </SectionTemplate>
  );
};
